<template>
  <v-form ref="form" lazy-validation autocomplete="off">
    <v-card>
      <v-card-title>
        <h3 class="headline mb-0">{{ $t('title.cadastrar_pre_usuario') }}</h3>
      </v-card-title>
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              id="pre-usuario-email"
              :label="`${$t('label.email')} *`"
              v-model="usuario.email"
              required
              :rules="[rules.requiredText]"
              maxlength="50"
              counter>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-text-field
              id="pre-usuario-matricula"
              :label="$t('label.matricula')"
              v-model="usuario.matricula"
              maxlength="50"
              counter>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              id="pre-usuario-perfil"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.perfil', 1)} *`"
              v-model="usuario.idPerfil"
              :items="perfis"
              item-text="nomPerfil"
              item-value="id"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              id="usuario-estruturausuario"
              class="custom-autocomplete"
              required
              :rules="[rules.required]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.estrutura_usuario', 1)} * `"
              v-model="usuario.idEstruturaUsuario"
              :items="estruturasUsuario"
              item-text="nomEstruturaUsuario"
              item-value="id"
              @input="estruturaUsuarioSelecionada"
              clearable>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              id="usuario-divisao"
              class="custom-autocomplete"
              :disabled="!usuario.idEstruturaUsuario"
              required
              :rules="[rules.requiredTags]"
              :no-data-text="$tc('message.nenhum_registro', 1)"
              :label="`${$tc('label.divisao', 1)} * `"
              v-model="usuario.idsDivisao"
              :items="divisoes"
              item-text="nome"
              item-value="id"
              multiple
              chips
              deletable-chips>
            </v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="cancelar">{{ $t('label.cancelar') }}</v-btn>
        <v-btn @click.native="abrirDialogSalvar" color="primary">{{ $t('label.salvar') }}</v-btn>
      </v-card-actions>
    </v-card>
    <confirm
      ref="confirmDialog"
      :message="$t('message.deseja_salvar', {text: $t('label.cadastrar_pre_usuario')})"
      :persistent="true"
      @agree="salvar">
    </confirm>
  </v-form>
</template>

<script>
import Confirm from '../../shared-components/vuetify/dialog/Confirm';

export default {
  name: 'CadastroPreUsuarioForm',
  components: {
    Confirm,
  },
  props: {
    idUsuario: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      resources: this.$api.usuarioPreCadastro(this.$resource),
      perfilResource: this.$api.perfil(this.$resource),
      estruturaUsuarioResource: this.$api.estruturaUsuario(this.$resource),
      divisaoResource: this.$api.divisao(this.$resource),
      perfis: [],
      estruturasUsuario: [],
      divisoes: [],
      usuario: {
        id: null,
        email: '',
        idEstruturaUsuario: null,
        idPerfil: null,
        matricula: null,
        idsDivisao: [],
      },
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        requiredText: (value) => (!!value && !!value.trim()) || this.$t('message.campo_obrigatorio'),
        requiredTags: (value) => value.length > 0 || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  methods: {
    abrirDialogSalvar() {
      if (!this.$refs.form.validate()) return;
      this.$refs.confirmDialog.open();
    },
    salvar() {
      if (this.idUsuario) {
        this.resources.atualizar({ id: this.idUsuario }, this.usuario).then(() => {
          this.$toast(this.$t('message.atualizado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      } else {
        this.resources.gravar(this.usuario).then(() => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          this.$emit('atualizarGrid');
        }, (err) => {
          this.$error(this, err);
        });
      }
    },
    cancelar() {
      this.$refs.form.reset();
      this.$emit('cancelar');
    },
    carregar() {
      const id = this.idUsuario;
      this.resources.obter({ id }).then((response) => {
        this.usuario = response.data;
        this.buscarDivisoes();
      }, (err) => {
        this.$error(this, err);
        this.cancelar();
      });
    },
    buscarPerfis() {
      this.perfilResource.selecao({ limit: 500 })
        .then((res) => {
          this.perfis = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarEstruturasUsuario() {
      this.estruturaUsuarioResource.selecao({ limit: 500 })
        .then((res) => {
          this.estruturasUsuario = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    buscarDivisoes() {
      const params = {
        cadastroUsuario: true,
        limit: 500,
      };

      if (this.usuario.idEstruturaUsuario) {
        params.idEstruturaUsuario = this.usuario.idEstruturaUsuario;
      }
      return this.divisaoResource.autocomplete(params)
        .then((res) => {
          this.divisoes = res.data;
        })
        .catch((err) => {
          this.$toast(err.data.error);
        });
    },
    estruturaUsuarioSelecionada() {
      this.usuario.idsDivisao = [];
      this.buscarDivisoes();
    },
  },
  mounted() {
    this.buscarPerfis();
    this.buscarEstruturasUsuario();
    if (this.idUsuario) {
      this.carregar();
    }
  },
};
</script>
