<template>
  <div>
    <v-dialog v-model="dialogForm" persistent max-width="1200">
      <cadastro-pre-usuario-form
        v-if="dialogForm"
        :id-usuario="idUsuario"

        @cancelar="fecharForm"
        @atualizarGrid="buscar">
      </cadastro-pre-usuario-form>
    </v-dialog>
    <cadastro-pre-usuario-tabela
      @edit="edit"
      ref="tabela">
    </cadastro-pre-usuario-tabela>
    <v-btn
      @click="abrirForm"
      fixed fab bottom right
      color="primary" class="Calendario__btn--floating__heigth">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>
<script>
import CadastroPreUsuarioTabela from './CadastroPreUsuarioTabela';
import CadastroPreUsuarioForm from './CadastroPreUsuarioForm';
import { generateComputed } from '../../common/functions/roles-computed-generator';

export default {
  name: 'CadastroPreUsuario',
  data() {
    return {
      dialogForm: false,
      idUsuario: null,
    };
  },
  components: {
    CadastroPreUsuarioForm,
    CadastroPreUsuarioTabela,
  },
  computed: {
    ...generateComputed('Pre Usr', [
      'canAccessPage',
    ]),
  },
  methods: {
    edit(id) {
      this.idUsuario = id;
      this.abrirForm();
    },
    buscar() {
      this.fecharForm();
      this.$refs.tabela.filtrar();
    },
    abrirForm() {
      this.dialogForm = true;
    },
    fecharForm() {
      this.idUsuario = null;
      this.dialogForm = false;
    },
  },
  mounted() {
    if (!this.canAccessPage) {
      window.setTimeout(() => {
        this.$router.push({ name: 'inicio' });
      }, 1E3);
    }
  },
};
</script>

<style>
.Calendario__btn--floating__heigth > div {
  height: auto !important;
}
</style>
